import { useState, useEffect, useCallback } from 'react';
import './lb.css';
import Navbar5 from './Navbar5';
import Navbar1 from './Navbar1';
import axios from 'axios';
import Spinner2 from './Spinner2';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useWallet } from '@solana/wallet-adapter-react';

function LeaderVideo() {
	const [data, setData] = useState(null); // Data fetched from first API (array)
	const [isLoading, setIsLoading] = useState(true); // Loading state
	const [details, setDetails] = useState([]); // Store details for multiple movies/TV shows
	const fetchUrl = `https://mateys.xyz/web_api/video.php`;
	const base_url = 'https://image.tmdb.org/t/p/original';

	// Fetch initial data
	const fetchData = useCallback(async () => {
	    try {
	      const response = await axios.get(fetchUrl, {
	        headers: {
	          accept: 'application/json',
	        },
	      });
	      setData(response.data); // Store the fetched array data
	      setIsLoading(false);

	      // Assuming response.data is an array of items with a videoId or similar field
	      // const videoIds = response.data.map(item => item.tmdb_id);

	      // After fetching the data, fetch details for each videoId
	      if (response.data.length > 0) {
	        response.data.forEach(item => {
	          fetchDetails(item.tmdb_id, item.amount);
	        });
	      }
	    } catch (error) {
	      console.log('Error fetching initial data:', error);
	      setIsLoading(false);
	    }
	}, [fetchUrl]);

	// Function to fetch movie/TV show details
	const fetchDetails = async (id, amount) => {
	    try {
	        // Try fetching from the movie endpoint
	        let response = await fetch(`https://api.themoviedb.org/3/movie/${id}?language=en-US`, {
	        	method: 'GET',
			    headers: {
				    accept: 'application/json',
				    Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJkYmQzOGRlNzRlYTUwZDRkNDE5Mzk0OTM0OTczYTA0MCIsInN1YiI6IjY1OWUyYzkzOGU4ZDMwMDE0YzIwMjExYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.D0rTluAv1sY7ne6WtCShIY8TfJIsx7HQ6FajTpbXC-w'
				}
	        });
	        let data = await response.json();

		    if (data && data.id) {
		        // If it's a movie, update the details state with movie info
		        // console.log('Movie found:', data, amount);
		        const updatedData = { ...data, amount };
		        setDetails(prevDetails => [...prevDetails, updatedData]);
		        return;
		    }

	        // If not a movie, try fetching from the TV endpoint
	        response = await fetch(`https://api.themoviedb.org/3/tv/${id}?language=en-US`, {
	      	    method: 'GET',
			    headers: {
				    accept: 'application/json',
				    Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJkYmQzOGRlNzRlYTUwZDRkNDE5Mzk0OTM0OTczYTA0MCIsInN1YiI6IjY1OWUyYzkzOGU4ZDMwMDE0YzIwMjExYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.D0rTluAv1sY7ne6WtCShIY8TfJIsx7HQ6FajTpbXC-w'
				}
	        });
	        data = await response.json();

	        if (data && data.id) {
		        // If it's a TV show, update the details state with TV show info
		        // console.log('TV Show found:', data, amount);
		        const updatedData = { ...data, amount };
		        setDetails(prevDetails => [...prevDetails, updatedData]);
		        return;
	        }

	        console.log('No movie or TV show found for this ID');
	    } 
	    catch (error) {
	      console.log('Error fetching movie/TV details:', error);
	    }
	};

	useEffect(() => {
		fetchData()
	}, [fetchData])

	function truncate(str, n) {
  		// console.log(width, str?.length > n ? str.substr(0, n - 1) + "..." : str);
		return str?.length > n ? str.substr(0, n - 1) + "..." : str;
	}

	const navigate = useNavigate();
    const { disconnect } = useWallet();

    const isPremium = Cookies.get('bool');
	const isLoggedIn = Cookies.get('isLoggedIn');
    // console.log("nav1", isPremium, isPremium !== 'yes');

	const logoutClickHandler = () => {
	    Cookies.remove('isLoggedIn');
	    Cookies.remove('userId');
	    Cookies.remove('uname');
    	Cookies.remove('bool');
		Cookies.remove('isSkip');
		Cookies.remove('isWallet');
		disconnect();
	    navigate("/");
  	}

  	// console.log(details);

	return (
		<div className="bg-black text-white">
			{isLoading ? (
        		<Spinner2 />
      		) : (
		        <div className="container-fluid lb__div1">
		          	<div className="row">
		            	<Navbar5 />

		            	<div className="col-12 col-sm-12 lb__div2">
							<div className="text-uppercase text-center text-white mb-5">
								Videos Score
							</div>

							<div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-3 border-0 lv_div3">
		            			{details && details.map((i, index) => (
		            				<>
										<div className="col" key={index}>
										    <div className="card bg-transparent border-light text-white h-100">
										      <img src={`${base_url}${i.poster_path}`} className="card-img-top" alt="pirates.tv" />
										      <div className="card-body">
										        <h5 className="card-title">{i?.title || i?.original_title}</h5>
										        <p className="card-text">{truncate(i.overview, 90)}</p>
										      </div>
										      <div className="card-footer text-center">
											    <small className="text-body-secondary">{i?.amount} SOL</small>
											  </div>
										    </div>
										</div>
									</>
		            			))}
		            		</div>
		            	</div>

				        <div className="col-12 col-sm-12" style={{ 'height': '100px' }}></div>

		            	{isLoading && <Spinner2 />}

					    <div 
					    	className="text-white d-flex justify-content-evenly align-items-center border-0" 
					    	id="navbar10" 
					    	style={{ backgroundColor: '#111', height: '45px' }}
					    ></div>

						<Navbar1 />
		          	</div>
		        </div>
		    )}
	    </div>
	);
}

export default LeaderVideo;